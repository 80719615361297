import React from 'react';
import './index.css';
import bgPath from "../../assets/media/bg/Boxcheck_Logo_Primary.png";
import loadergif from '../../assets/media/bg/loader.gif';
const Login = () => {
  return (
    <div >
      <div >
        <div>
          <div style={{display:"flex",justifyContent:"center"}} >
            <div className="kt-login__body" style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginTop:"10vh"
            }}>

              <div className="" style={{margin: "4rem auto", width: "100%",maxWidth:"450px" }}>
                  <div className="" style={{
                        display:"block",
                        textAlign:"center",
                        marginBottom:"1rem",
                    }}>
                    <img  alt="Logo" style={{
                        display:"block",
                        width:"100%",
                        marginRight:"auto",
                        backgroundColor:"transparent"
                    }} className="" src={bgPath} />
                  </div>
                  <div className="align_">
                    <img className="svg_pre_loader" src={loadergif} alt='loader'></img>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;