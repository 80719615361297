import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import "./index.scss";
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider
} from "./_metronic/layout";
import retry from "./services/utills/retry";
import AppLoader from './components/ApplicationLoader/index';
const App = React.lazy(() =>
  retry(() => import('./App'))
);

ReactDOM.render(<MetronicLayoutProvider>
  <MetronicSubheaderProvider>
    <MetronicSplashScreenProvider>
      <Suspense fallback={<AppLoader />}>
        <App />
      </Suspense>
    </MetronicSplashScreenProvider>
  </MetronicSubheaderProvider>
</MetronicLayoutProvider>, document.getElementById('root'));

