import React from "react";
import {createMuiTheme, ThemeProvider} from "@material-ui/core";

const theme = createMuiTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ["Poppins"].join(",")
    },

    palette: {
      action : {
        active : '#111349'
      },
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#ED5627",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: "#3783e7",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      },
     
      error: {
        // light: will be calculated from palette.primary.main,
        main: "#f018a6",
        // dark: will be calculated from palette.primary.main,
        // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
      }
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */
    props: {
      // Name of the component ⚛️
      MuiButtonBase: {
        // The properties to apply,
        color:'#fffff',
        disableRipple: true // No more ripple, on the whole application 💣!
      },

      // Set default elevation to 1 for popovers.
      MuiPopover: {
        elevation: 1
      }
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          // "& $notchedOutline": {
          //   borderColor: "green"
          // },
          "&:hover $notchedOutline": {
            borderColor: "#ED5627"
          },
          // "&$focused $notchedOutline": {
          //   borderColor: "purple"
          // },
          "&&& $input": {
            padding: "1px"
          }
        }
      },
      MUIDataTableBodyCell: {
        root: {
          // height: "50px !important"
        },

      },
      MUIDataTable: {
        responsiveScroll: {
          "maxHeight": "unset",
        },
      },
    }
  }
);

export function MaterialThemeProvider(props) {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
